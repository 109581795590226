.teamTitle {
  font-size: 34px;
  font-weight: bolder;
  margin: 0;
}

.divisionTitle {
  color: gray;
  margin: 3px 0px 3px 0px;
  font-size: 1rem;
  font-weight: 400;
}