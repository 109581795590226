.mainContainer {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  display: block;
  background-color: white;
  min-height: 70vh;
  padding-left: 16px;
  padding-right: 16px;
}

.displayContainer {
  box-sizing: border-box;
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.heading {
  max-width: none;
  box-sizing: border-box;
  flex-direction: row;
  width: 100%;
}

.dataContainer {
  max-width: none;
  width: 100%;
  box-sizing: border-box;
  margin: 0px;
  flex-direction: row;
  padding-top: 1em;
}

.divisionTitle {
  background-color: black;
  color: white;
  font-size: 14px;
  font-weight: 500;
  padding: 0.5em;
  text-align: left;
}

.teamList {
  display: flex;
  flex-wrap: wrap;
  padding-top: 10px;
  padding-bottom: 20px;
}

@media screen and (min-width: 600px) {

  .mainContainer {
    padding-left: 24px;
    padding-right: 24px;
  }

}



@media screen and (min-width: 1536px) {

  .mainContainer {
    max-width: 1536px;
  }

}