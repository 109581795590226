.table {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  overflow-x: auto;
  table-layout: fixed;
}

.tableHeadCell {
  background-color: black;
  color: white;
  font-size: 14px;
  font-weight: 500;
  padding: 0.5em;
  text-align: left;
}

.tableDataCell {
  font-size: 14px;
  font-weight: 400;
  padding: 0.5em;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgba(0, 0, 0, 0.15);
  white-space: nowrap;
  height: 40px;
}

.tableDataRow:last-child td th {
  border: 0;
}

.tableDataRow:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.04);
}

@media screen and (max-width: 900px) {

  .tableHeadCell {
    font-size: 10px;
    font-weight: 500;
  }

  .tableDataCell {
    font-size: 10px;
    height: 20px;
  }
}