.selectorButton {
  background-color: black;
  box-sizing: inherit;
  -webkit-font-smoothing: antialiased;
  align-items: center;
  background: black;
  border-style: initial;
  border-width: 0;
  border-radius: 0;
  box-shadow: inset 0 0 0.5px 1px hsla(0, 0%, 100%, 0.075), 0 0.9px 1.5px rgba(0, 0, 0, 0.03), 0 3.1px 5.5px rgba(0, 0, 0, 0.08), 0 14px 25px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  line-height: 1.2;
  padding: 9px 18px;
  position: relative;
  text-decoration: none;
  transition: all .2s;
  white-space: nowrap;
  will-change: transform;
  margin-right: 1em;
  margin-bottom: 1em;
  color: white;
}

.selectorButton:focus {
  outline: 0;
}

.selectorButton:active {
  transform: translateY(2px);
}

.selectorButton:hover {
  background: gray;
}