.logoSmall {
  mix-blend-mode: multiply;
  width: 40px;
  height: 40px;
}

.logoMedium {
  mix-blend-mode: multiply;
  width: 100px;
  height: 100px
}

.logoLarge {
  mix-blend-mode: multiply;
  width: 200px;
  height: 200px;
}

.avatarSmall {
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 7px;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: bolder;
}

.avatarMedium {
  display: flex;
  width: 100px;
  height: 100px;
  border-radius: 7px;
  align-items: center;
  justify-content: center;
  font-size: 45px;
  font-weight: bolder;
}

.avatarLarge {
  display: flex;
  width: 200px;
  height: 200px;
  border-radius: 24px;
  align-items: center;
  justify-content: center;
  font-size: 90px;
  font-weight: bolder;
}

@media screen and (max-width: 900px) {

  .logoSmall {
    width: 20px;
    height: 20px;
  }

  .logoMedium {
    mix-blend-mode: multiply;
    width: 40px;
    height: 40px;
  }

  .logoLarge {
    mix-blend-mode: multiply;
    width: 100px;
    height: 100px;
  }

  .avatarSmall {
    display: flex;
    width: 20px;
    height: 20px;
    border-radius: 3px;
    background-color: cyan;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bolder;
  }

  .avatarMedium {
    display: flex;
    width: 40px;
    height: 40px;
    border-radius: 6px;
    background-color: cyan;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bolder;
  }

  .avatarLarge {
    display: flex;
    width: 100px;
    height: 100px;
    border-radius: 12px;
    align-items: center;
    justify-content: center;
    font-size: 45px;
    font-weight: bolder;
  }
}