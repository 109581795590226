.card {
  display: flex;
  flex-direction: column;
  width: 100px;
  padding: 50px;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  white-space: nowrap;
}

.card:hover {
  transform: translateY(-5px);
  transform: scale(1.1);

}

@media screen and (max-width: 900px) {

  .card {
    padding: 25px;
    font-size: medium;
  }
}